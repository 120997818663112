.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #565656;
  border-color: #565656;
  padding: 2px 12px;
  height: auto;
  color: #fff !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #fff;
}

// 禁用
.ant-btn:disabled,
.ant-btn.ant-btn-disabled {
  background: #343434;
  border-color: #343434;
  color: #939393;
}

.header {
  // width: 100%;
  height: 23px;
  padding: 6.5px 8.5px;
  background: #1d1d1d;
}
.pageTitle {
  padding: 15px 20px;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  height: 62px;
  line-height: 32px;
  top: 0;
  img {
    width: 17px;
    height: 32px;
    position: absolute;
    left: 20px;
  }
}
.section {
  height: calc(100vh - 36px);
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #262626;
  height: 100%;

  .ant-image {
    max-height: 100%;
  }

  //   > img {
  //     width: 23.5px;
  //     height: 24px;
  //   }

  > p {
    font-size: 9px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 33px;
    opacity: 0.45;
  }
  .preview {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.right {
  height: 100%;
  .List {
    width: 100%;
    height: calc(100vh - 36px - 32px - 118px);
    overflow-y: auto;

    background-image: linear-gradient(#313131 50%, #434343 50%);
    background-size: 100% 422px;

    .item {
      height: 211px;
      background-color: #313131;
      padding: 23px;
      &.grey {
        background-color: #434343;
      }
      &.active {
        .iconBg {
          border-color: #1b56ff;
        }
      }
      .iconBg {
        max-height: 100%;
        border: 2px solid transparent;
        border-radius: 9px;
        cursor: pointer;
        .icon {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background-color: #000;
          border-radius: 9px;
        }
        &:hover {
          opacity: 0.8;
        }
      }

      .contentText {
        margin-top: 20px;
        padding: 10px 12px;
        background: #565656;
        border-radius: 9px;
        color: #ffffff;
      }

      .ant-progress-text {
        // font-size: 24px;
        // font-family: PingFang SC;
        font-weight: 400;
        color: #fff;
      }
    }
  }
  .Upload {
    width: 100%;
    .ant-upload {
      width: 100%;
    }
  }
  .title {
    // font-size: 24px;
    // font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  .ErrorText {
    color: #f00;
    font-weight: bold;
  }
  .Add {
    width: 100%;
    border-radius: 0;
  }
  .Tools {
    width: 100%;
    // height: 47px;
    padding: 20px 0 24px 0;
    background: #1d1d1d;
  }
}

.flex-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.ant-btn-primary {
  background-color: #1b56ff;
}
.ant-btn {
  border-radius: 23px;
  padding: 0 20px;

  & + .ant-btn {
    margin-left: 20px;
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #262626;
  .modalImg {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .closeIcon {
    position: absolute;
    top: 44px;
    right: 44px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
