.SlideDiff {
  width: 100%;
  max-height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .SlideCol {
    position: absolute;
    height: 100%;

    .SlideImg {
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;
      // .diffImg {
      //   // position: absolute;
      //   // max-width: fit-content;
      //   // max-height: fit-content;
      // }
      -webkit-user-select: none; /* Safari/Chrome */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+ */
      user-select: none; /* 其他浏览器 */
    }

    .SlideBar {
      // position: absolute;
      display: inline-flex;
      position: absolute;
      // left: 50%;
      top: 0;
      height: 100%;
      transform: translateX(-50%);
      justify-content: center;
      align-items: center;
      // width: 20px;
      // background-color: #f008;
      .bar {
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: #fff;
      }
      .bar_img {
        width: 77px;
        height: 42px;
        // user-select: none;
        cursor: move;
      }
      background: url("../../assets/SilderBar.png") no-repeat 50%;
    }
  }

  .diffImg {
    // max-width: 100%;
    min-width: 80%;
    max-height: 100%;
    object-fit: contain;
    -webkit-user-select: none; /* Safari/Chrome */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none; /* 其他浏览器 */
  }

  .tip {
    position: absolute;
    top: 20px;
    padding: 10px 20px;
    background: #000000;
    opacity: 0.45;
    border-radius: 36px;

    // font-size: 27px;
    font-weight: 400;
    color: #ffffff;
    // line-height: 78px;
  }

  .tool {
    position: absolute;
    right: 15%;
    bottom: 20px;
  }
}
